<template>
  <a-result status="404" title="404" :sub-title="subTitle">
    <template #extra>
      <a-button type="primary" @click="toHome">{{ btnTitle }}</a-button>
    </template>
  </a-result>
</template>

<script>
import { getLanguage } from '@/utils/languageUtils'
export default {
  name: 'Exception404',
  data() {
    return {
      subTitle: 'Sorry, the page you visited does not exist.',
      btnTitle: 'Back Home',
    }
  },
  created() {
    this.changeLanguage(getLanguage(this.$route))
  },
  methods: {
    toHome() {
      this.$router.push({
        path: '/',
        query: { locale: getLanguage(this.$route) },
      })
    },
    // 更改语言
    changeLanguage(language) {
      this.subTitle =
        language === 'enUS'
          ? 'Sorry, the page you visited does not exist!'
          : '不好意思，该页面不存在！'
      this.btnTitle = language === 'enUS' ? 'Back Home' : '返回首页'
    },
  },
}
</script>
